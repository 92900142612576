import { Button, ButtonProps, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface Props {
	open: boolean;
	title: string;
	description: string;
	cancelProps: ButtonProps
	confirmProps: ButtonProps
}

const ConfirmationDialog = ({
	open,
	title,
	description,
	cancelProps,
	confirmProps
} : Props ) => {

	/* Hooks & Use Effects */

	/* States */

	/* API Calls */

	/* Functions & Functional Components */

	return (
		<Dialog open={open} onClose={cancelProps.onClick}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContentText>{description}</DialogContentText>
			<DialogActions>
				<Button {...cancelProps}>
					{cancelProps.children ?? 'Cancel'}
				</Button>
				<Button {...confirmProps}>
					{confirmProps.children ?? 'Confirm'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
