import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  SvgIconProps,
} from "@mui/material";
import React from "react";

export interface ButtonProps extends MuiButtonProps {
  label?: string;
  startIcon?: React.ReactElement<SvgIconProps>;
  endIcon?: React.ReactElement<SvgIconProps>;
  loading?: boolean;
  to?: string;
}

const Button = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      startIcon,
      loading = false,
      endIcon,
      label,
      children,
      variant = "text",
      ...rest
    } = props;
    return (
      <MuiButton
        ref={ref}
        variant={variant}
        {...rest}
        startIcon={
          startIcon &&
          (loading ? <CircularProgress size={20} color="inherit" /> : startIcon)
        }
        endIcon={
          loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : endIcon ? (
            endIcon
          ) : null
        }
      >
        {children || label}
      </MuiButton>
    );
  }
);

export default Button;
